import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Viewing from "../components/viewing";

// Patterns
import Arrow from "../images/icons/arrow_forward.svg";

export default function Catalog({
  data: {
    page,
    projects,
    cottages,
    contacts,
    forms,
    header,
    footer,
    socials,
    copyright,
    user,
    cookies,
  },
}) {
  const wpContent = page.nodes[0];
  const cottage = cottages.nodes[0].blockCotteges;

  forms = forms.nodes[0]
  contacts = contacts.nodes[0]

  return (
    <Layout
      footer={footer.nodes}
      header={header.nodes}
      forms={forms}
      user={user.nodes}
      cookies={cookies.nodes}
      copyright={copyright.nodes}
      socials={socials.nodes[0].socialNetworks}
      locale={page.nodes[0].locale.locale}
      uri={page.nodes[0].uri}
    >
      <SEO title={wpContent.seo.title} description={wpContent.seo.metaDesc} />
      <header className="head noBorder">
        <nav className="breadcrumb" aria-label="Breadcrumb">
          <ol className="breadcrumb__list">
            {wpContent.seo.breadcrumbs.map((value, i) => (
              <li key={i} className="breadcrumb__item">
                <Link
                  activeClassName="breadcrumb__link__active"
                  className="breadcrumb__link"
                  to={value.url}
                  dangerouslySetInnerHTML={{
                    __html: value.text,
                  }}
                ></Link>
              </li>
            ))}
          </ol>
        </nav>
        <h1>{wpContent.title}</h1>
        {/* <div className="switchers">
          <div>
            <span>{wpContent.pageProjectsCatalog.architectureFilterText}</span>
            <div className="switcher active">
              {wpContent.pageProjectsCatalog.architectureFilterAll}
            </div>
            <div className="switcher">
              {wpContent.pageProjectsCatalog.architectureFilterModern}
            </div>
            <div className="switcher">
              {wpContent.pageProjectsCatalog.architectureFilterClassic}
            </div>
          </div>
          <div>
            <span>{wpContent.pageProjectsCatalog.floorsFilterText}</span>
            <div className="switcher active">
              {wpContent.pageProjectsCatalog.architectureFilterAll}
            </div>
            <div className="switcher">1</div>
            <div className="switcher">2</div>
          </div>
        </div> */}
      </header>
      <Tabs>
        <header className="tabsHead">
          <strong>{cottage.textBeforeToggler}</strong>
          <TabList>
            <Tab>{cottage.modernToggleText}</Tab>
            <Tab>{cottage.classicToggleText}</Tab>
          </TabList>
        </header>
        <TabPanel>
          <div className="cottages projectsStyle">
            {projects.edges.map(({ node }, i) => {
              if (node.cottagesTermsType.nodes[0].slug === 'modern') {
                return (
                  <div
                    key={i}
                    className="cottage__item"
                  >
                    <div>
                      <div>
                        <h2 className="smallStyle">{node.title}</h2>
                        <div className="tags">
                          {node.cottagesTerms.nodes.map((item, i) => (
                            <span
                              className={`${item.slug === "plan" ? "outline" : ""}`}
                              key={i}
                            >
                              {item.name}
                            </span>
                          ))}
                        </div>
                        <span className="price">
                          {node.singleProjectContent.cottagePrice} $
                        </span>
                      </div>
                      <div className="cottage__details smallStyle">
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeTotalAreaIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeTotalArea}</span>
                          <b>{node.singleProjectContent.cottageTotalArea}</b>
                        </div>
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeLevelsIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeLevels}</span>
                          <b>{node.singleProjectContent.cottageNumberOfFloors}</b>
                        </div>
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeTotalCarsIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeTotalCars}</span>
                          <b>{node.singleProjectContent.cottageNumberOfRooms}</b>
                        </div>
                      </div>
                      <Link to={node.uri} className="button lined">
                        {
                          cottage.cottegesList[0].cottegeAdvParamsGroup
                            .buttonMoreText
                        }{" "}
                        <Arrow />
                      </Link>
                    </div>
                    <div className="cottage__image">
                      <GatsbyImage
                        image={
                          node.singleProjectContent.cottageGallery[0].localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={
                          node.singleProjectContent.cottageGallery[0].altText || ``
                        }
                      />
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="cottages projectsStyle">
            {projects.edges.map(({ node }, i) => {
              if (node.cottagesTermsType.nodes[0].slug === 'chalet') {
                return (
                  <div
                    key={i}
                    className="cottage__item"
                  >
                    <div>
                      <div>
                        <h2 className="smallStyle">{node.title}</h2>
                        <div className="tags">
                          {node.cottagesTerms.nodes.map((item, i) => (
                            <span
                              className={`${item.slug === "plan" ? "outline" : ""}`}
                              key={i}
                            >
                              {item.name}
                            </span>
                          ))}
                        </div>
                        <span className="price">
                          {node.singleProjectContent.cottagePrice} $
                        </span>
                      </div>
                      <div className="cottage__details smallStyle">
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeTotalAreaIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeTotalArea}</span>
                          <b>{node.singleProjectContent.cottageTotalArea}</b>
                        </div>
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeLevelsIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeLevels}</span>
                          <b>{node.singleProjectContent.cottageNumberOfFloors}</b>
                        </div>
                        <div>
                          <div
                            className="ico"
                            dangerouslySetInnerHTML={{
                              __html: cottage.cottegeTotalCarsIcon,
                            }}
                          ></div>
                          <span>{cottage.cottegeTotalCars}</span>
                          <b>{node.singleProjectContent.cottageNumberOfRooms}</b>
                        </div>
                      </div>
                      <Link to={node.uri} className="button lined">
                        {
                          cottage.cottegesList[0].cottegeAdvParamsGroup
                            .buttonMoreText
                        }{" "}
                        <Arrow />
                      </Link>
                    </div>
                    <div className="cottage__image">
                      <GatsbyImage
                        image={
                          node.singleProjectContent.cottageGallery[0].localFile
                            .childImageSharp.gatsbyImageData
                        }
                        alt={
                          node.singleProjectContent.cottageGallery[0].altText || ``
                        }
                      />
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </TabPanel>
      </Tabs>
      <Viewing forms={forms} contacts={contacts} />
    </Layout>
  );
}

export const query = graphql`
  {
    copyright: allWpRepeatableBlocks(filter: { slug: { eq: "copyrights" } }) {
      nodes {
        slug
        title
        blockCopyrights {
          copyrightTextAfter
          copyrightTextBefore
          copyrightTextMiddle
          userAgreement {
            linkText
          }
          cookiesPolicy {
            linkText
          }
        }
        uri
        locale {
          locale
        }
      }
    }
    user: allWpRepeatableBlocks(
      filter: { slug: { eq: "ugoda-korystuvacha" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    cookies: allWpRepeatableBlocks(
      filter: { slug: { eq: "polityka-cookies" } }
    ) {
      nodes {
        slug
        title
        uri
        locale {
          locale
        }
        blockUserAgreement {
          agreementBlockId
          userAgreementText
          userAgreementButtonText
          userAgreementTitle
        }
      }
    }
    page: allWpPage(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "cottages" } }
    ) {
      nodes {
        pageProjectsCatalog {
          architectureFilterAll
          architectureFilterClassic
          architectureFilterModern
          architectureFilterText
          floorsFilterText
          viewProjectButtonText
        }
        uri
        title
        slug
        seo {
          title
          metaDesc
          breadcrumbs {
            text
            url
          }
        }
        locale {
          locale
        }
      }
    }
    projects: allWpSingleCottage(
      filter: {locale: {locale: {eq: "uk"}}}
      sort: {fields: singleProjectContent___cottagePrice, order: ASC}
    ) {
      edges {
        node {
          title
          locale {
            locale
          }
          uri
          slug
          singleProjectContent {
            cottagePrice
            cottageTotalArea
            cottageNumberOfFloors
            cottageNumberOfRooms
            cottageGallery {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: DOMINANT_COLOR)
                }
              }
            }
          }
          cottagesTerms {
            nodes {
              name
              slug
            }
          }
          cottagesTermsType {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    cottages: allWpRepeatableBlocks(
      filter: { slug: { eq: "cotteges" }, locale: { locale: { eq: "uk" } } }
    ) {
      nodes {
        blockCotteges {
          classicToggleText
          cottegeLevels
          cottegeLevelsIcon
          cottegeLivingArea
          cottegeLivingAreaIcon
          cottegeTotalArea
          cottegeTotalAreaIcon
          cottegeTotalCars
          cottegeTotalCarsIcon
          modernToggleText
          textBeforeToggler
          cottegesList {
            cottegeAdvParamsGroup {
              buttonMoreText
            }
          }
        }
        slug
        uri
        locale {
          locale
        }
      }
    }
    contacts: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "kontaktna-informacziya" }
      }
    ) {
      nodes {
        slug
        title
        blockContacts {
          contactAddressText
          contactAddressTitle
          contactEmailAdressText
          contactEmailAdressTitle
          contactPhonesListTitle
          fieldGroupName
          scheduleListTitle
          contactPhonesList {
            phoneNumber
          }
          scheduleList {
            item
          }
        }
      }
    }
    forms: allWpRepeatableBlocks(
      filter: { locale: { locale: { eq: "uk" } }, slug: { eq: "formy" } }
    ) {
      nodes {
        formSettings {
          contactFormCommentPlaceholder
          contactFormEmailPlaceholder
          contactFormSendButton
          contactFormTitle
          invalidMailMsg
          invalidPhoneMsg
          modalCallbackButtonText
          modalCallbackTitle
          modalThanksButtonText
          modalThanksText
          modalThanksTitle
          modalViewingButtonText
          modalViewingFormTitle
          viewingFormDatetime
          viewingFormSendButton
          viewingFormTitle
          viewingFormUsername
        }
      }
    }
    footer: allWpRepeatableBlocks(filter: { slug: { eq: "footer" } }) {
      nodes {
        mainFooter {
          copyrightText
          footerLogo
          orderButtonIcon
          orderButtonText
          centrColumnItems {
            item
            name
          }
          leftColumnItems {
            name
            item
          }
          rightColumnItems {
            item
            name
          }
        }
        slug
        title
        locale {
          locale
        }
      }
    }
    header: allWpRepeatableBlocks(filter: { slug: { eq: "header" } }) {
      nodes {
        slug
        title
        locale {
          locale
        }
        mainHeader {
          headerLogo
          headerSubmenu {
            dropdownBtnIcon
            dropdownPages {
              pageTitle
              pageLink
            }
          }
          menuCallbackBtn {
            icon
            text
          }
          menuOrderHouseBtn {
            icon
            link
            text
          }
          headerMenuFix {
            pageLink
            pageTitle
          }
          headerMessengerMenu {
            messengerList {
              icon
              link
            }
            dropdownBtnIcon
          }
        }
      }
    }
    socials: allWpRepeatableBlocks(
      filter: {
        locale: { locale: { eq: "uk" } }
        slug: { eq: "soczialni-merezhi" }
      }
    ) {
      nodes {
        slug
        title
        locale {
          locale
        }
        socialNetworks {
          socialNetworks {
            icon
            link
            title
          }
        }
      }
    }
  }
`;
